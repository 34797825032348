<template>
  <div :class="['output-card', title.replace(/\s+/g, '-').toLowerCase()]">
    <router-link :to="routeTo()"><b-icon :icon="outputIcon()"></b-icon>{{ outputText() }}</router-link>
    <!-- <a class="output" @click='goToTab()'><b-icon :icon="outputIcon()"></b-icon>{{ outputText() }}</a> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'OutputCard',
  props: {
    title: {
      type: String,
      required: true
    },
    theme: {
      type: String,
      required: true,
      validator: (theme) => ['reports', 'simulations', 'insights'].includes(theme)
    },
    outputs: {
      type: Array,
      required: true
    },
    study: {
      type: Object,
      required: true
    }
  },
  methods: {
    outputText() {
      switch(this.theme) {
      case 'simulations':
        return 'View Simulations';
      case 'insights':
        return 'View Insights';
      case 'reports':
        return 'View Reports';
      }
    },
    outputIcon() {
      switch(this.theme) {
      case 'simulations':
        return 'lightning';
      case 'insights':
        return 'lightbulb';
      case 'reports':
        return 'file-earmark-text';
      }
    },
    goToTab() {
      switch(this.theme) {
      case 'simulations':
        this.$store.dispatch('project/setViewerMode', 'Viewer');
        this.resizeViewerCanvas(true);
        break;
      case 'insights':
        this.$store.dispatch('project/setViewerMode', 'Insights');
        this.resizeViewerCanvas(true);
        break;
      case 'reports':
        this.$store.dispatch('project/setCurrentReport', this.outputs[0]);
        this.$store.dispatch('project/setViewerMode', 'Reports');
        this.resizeViewerCanvas(true);
      }
    },
    routeTo() {
      switch(this.theme) {
      case 'simulations':
        return {
          name: 'ViewerContainer',
          params: {
            id: this.selectedProject.id,
            study: this.study.id,
            configuration: this.outputs[0].configurationId,
            tabName: 'Viewer'
          }
        };
      case 'insights':
        if(this.study.simulation_labels.length) {
          return {
            name: 'ViewerContainer',
            params: {
              study: this.study.id,
              configuration: this.study.simulation_labels[0].configurationId,
              tabName: 'insights'
            }
          };
        } else {
          return {
            name: 'ViewerContainerNoScenarios',
            params: {
              study: this.study.id,
              tabName: 'insights'
            }
          };
        }
      case 'reports':
        if(this.study.simulation_labels.length) {
          return {
            name: 'ViewerContainer',
            params: {
              study: this.study.id,
              configuration: this.study.simulation_labels[0].configurationId,
              tabName: 'reports'
            }
          };
        } else {
          return {
            name: 'ViewerContainerNoScenarios',
            params: {
              study: this.study.id,
              tabName: 'reports'
            }
          };
        }
      }
    },
    ...mapActions({
      resizeViewerCanvas: 'project/viewer/setWindowResizeRequired', //Force canvas to resize when collapse sidebar
    })
  },
  computed: {
    ...mapGetters('project', ['selectedProject'])
  }
};
</script>

<style scoped>
h5 {
  font-size: 1em;
  font-weight: 700;
  text-transform: capitalize;
}

div {
  margin-top: 0.25em;
}

h5:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 2em;
  height: 2em;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 0.3rem 0.35rem;
  background-size: 1.25em 1.25em;
}

.simulations h5:before {
  background-image: url('~@/assets/svg/simulation-icon.svg');
}

.reports h5:before {
  background-image: url('~@/assets/svg/report-icon.svg');
}

.output-card {
  margin-top: 1em;
}

a {
  font-weight: 700;
  font-size: 0.9em;
  margin-left: 2em;
}

a:hover {
  text-decoration: underline;
  /* color: var(--primary-blue); */
  cursor: pointer;
}

.output {
  color: var(--primary-blue);
  text-decoration: none;
  background-color: transparent;
}
</style>