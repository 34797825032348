<template>
  <div class="output-container">
    <div v-for="study in this.studies" :key="study.id">
      <h4>{{ study.name }}</h4>
      <output-card v-if="study.simulation_labels.length" title='Simulations' theme='simulations' :study="study" :outputs="study.simulation_labels" />
      <output-card v-if="study.insights.length" title='Insights' theme='insights' :study="study" :outputs="study.insights" />
      <output-card v-if="study.reports.length" title='Reports' theme='reports' :study="study" :outputs="study.reports" />
    </div>
  </div>
</template>

<script>
import OutputCard from './OutputCard';

export default {
  name: 'OutputContainer',
  components: {
    OutputCard
  },
  props: {
    studies: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped>
.output-container {
  border-top: 0.188rem solid var(--grey-300);
  border-bottom: 0.188rem solid var(--grey-300);
  padding: 1em 0;
}

h4 {
  font-size: 1.125em;
  font-weight: 700;
}

.output-container > * {
  margin: 0;
}
</style>