<template>
  <div>
    <svg version="1.1" class="wheel" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0rem" y="0rem"
      width="43.75rem" height="43.75rem" viewBox="0 0 700 700" enable-background="new 0 0 700 700" xml:space="preserve">

    <a v-on:click="$emit('update:selectedWedge', 12)" ref="12" :class="['slice',this.selectedWedgeClass(12)]" :id="12"  role="link" tabindex="0" xlink:target="_parent">
      <g class="segments">
        <!-- Middle -->
        <path :class="this.cssClassForServiceLineLevel(12, 2)" d="M394.188,196.695l20.54-76.656
        c-40.359-10.856-82.872-10.844-123.225,0.035l20.536,76.641C338.941,189.463,367.282,189.456,394.188,196.695z"/>
        <!-- Inner -->
        <path :class="this.cssClassForServiceLineLevel(12, 1)" d="M373.645,273.347l20.537-76.652
        c-26.906-7.238-55.247-7.231-82.149,0.021l20.538,76.639C346.022,269.73,360.192,269.727,373.645,273.347z"/>
        <!-- Outer -->
        <path :class="this.cssClassForServiceLineLevel(12, 3)" d="M414.723,120.04l20.539-76.654
        c-53.812-14.475-110.496-14.458-164.3,0.048l20.536,76.641C331.851,109.195,374.363,109.183,414.723,120.04z"/>
      </g>
      <g class="icon">
        <ellipse cx="351.427" cy="35.209" rx="30.076" ry="30.076"/>
        <g transform="translate(14.353 15.038)">
          <g>
            <path fill="#00AEEF" d="M327.945,12.632c-2.343,0-4.25-1.907-4.25-4.25s1.907-4.25,4.25-4.25s4.25,1.907,4.25,4.25
              S330.288,12.632,327.945,12.632z M327.945,6.132c-1.241,0-2.25,1.009-2.25,2.25s1.009,2.25,2.25,2.25s2.25-1.009,2.25-2.25
              S329.185,6.132,327.945,6.132z"/>
            <path fill="#00AEEF" d="M324.634,35.174c-0.695,0-1.389-0.265-1.917-0.794c-1.057-1.057-1.058-2.779,0-3.837l2.072-2.068V18.149
              c0-0.552,0.448-1,1-1s1,0.448,1,1v10.74c0,0.266-0.105,0.52-0.293,0.708l-2.364,2.361c-0.277,0.278-0.278,0.731,0,1.009
              c0.278,0.279,0.73,0.278,1.006,0.001l2.102-2.101c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-2.1,2.1
              C326.023,34.91,325.328,35.174,324.634,35.174z"/>
            <path fill="#00AEEF" d="M331.875,36.209c-1.495,0-2.711-1.215-2.713-2.71V27.88c0-0.552,0.448-1,1-1s1,0.448,1,1v5.617
              c0,0.392,0.32,0.711,0.713,0.711h0c0.393,0,0.712-0.32,0.712-0.712v-7.397l-2.622-2.737c-0.178-0.186-0.278-0.434-0.278-0.692
              v-8.26c0-0.552,0.448-1,1-1s1,0.448,1,1v7.858l2.622,2.737c0.178,0.186,0.278,0.434,0.278,0.692v7.8
              c-0.001,1.495-1.218,2.71-2.711,2.711C331.876,36.209,331.875,36.209,331.875,36.209z"/>
            <path fill="#00AEEF" d="M321.35,24.27c-0.552,0-1-0.448-1-1v-5.368c0-0.265,0.105-0.52,0.293-0.707l3.847-3.846
              c0.391-0.391,1.023-0.391,1.414,0s0.391,1.024,0,1.414l-3.554,3.553v4.954C322.35,23.822,321.902,24.27,321.35,24.27z"/>
            <path fill="#00AEEF" d="M335.827,23.632c-0.256,0-0.512-0.098-0.707-0.292l-1.823-1.822c-0.391-0.391-0.391-1.023,0-1.415
              c0.391-0.391,1.024-0.39,1.415,0l1.823,1.822c0.391,0.391,0.391,1.023,0,1.415C336.339,23.535,336.083,23.632,335.827,23.632z"
              />
          </g>
          <path fill="#00AEEF" d="M342.187,24.756c-0.568,0-1.139-0.18-1.62-0.548c-1.119-0.788-1.456-2.317-0.753-3.535
            c0.418-0.829,1.332-1.388,2.312-1.388c0.015,0,0.029,0,0.045,0c3.022,0.005,6.063,0.005,9.101,0c0.118,0,0.233-0.009,0.349-0.017
            l0.238-0.015c0.548-0.021,1.021,0.399,1.048,0.951c0.026,0.551-0.398,1.021-0.95,1.047l-0.201,0.012
            c-0.16,0.011-0.32,0.021-0.481,0.021c-3.039,0.006-6.081,0.006-9.122,0c-0.242-0.011-0.472,0.129-0.579,0.34
            c-0.208,0.364-0.12,0.761,0.178,0.971c0.304,0.23,0.685,0.201,0.921-0.044c0.385-0.397,1.018-0.406,1.414-0.021
            s0.406,1.017,0.021,1.414C343.586,24.482,342.888,24.756,342.187,24.756z M344.629,17.886c-2.01,0-4.02-0.003-6.029-0.016
            c-0.004,0-0.008,0-0.011,0c-1.971,0-3.585-1.608-3.591-3.589c-0.005-1.985,1.604-3.605,3.589-3.611c0.003,0,0.007,0,0.01,0
            c1.493,0,2.81,0.898,3.354,2.29c0.044,0.117,0.08,0.224,0.111,0.333c0.154,0.53-0.15,1.085-0.681,1.24
            c-0.538,0.152-1.086-0.152-1.239-0.681c-0.018-0.06-0.038-0.118-0.06-0.175c-0.238-0.608-0.806-1.026-1.491-1.006
            c-0.882,0.003-1.598,0.723-1.595,1.605c0.003,0.88,0.72,1.595,1.6,1.595c0.001,0,0.003,0,0.005,0
            c3.315,0.021,6.625,0.017,9.933,0.013l3.91-0.003c0.067,0,0.133-0.005,0.198-0.009l0.153,0.993l-0.029,1.003
            c-0.107,0.007-0.213,0.013-0.322,0.013l-3.908,0.003C347.235,17.885,345.931,17.886,344.629,17.886z M347.931,14.522
            c-0.922,0-1.854-0.009-2.78-0.043c-1.268-0.043-2.244-1.089-2.202-2.333c0-0.013,0.001-0.107,0.002-0.12
            c0.044-0.61,0.323-1.165,0.786-1.564c0.462-0.399,1.054-0.596,1.661-0.549c0.537,0.04,0.966,0.496,0.945,1.034
            c-0.02,0.538-0.441,0.963-0.979,0.963h-0.039c-0.169-0.006-0.24,0.031-0.281,0.066s-0.091,0.098-0.099,0.195
            c-0.003,0.185,0.109,0.305,0.251,0.31c1.415,0.053,2.835,0.045,4.206,0.037c0.841-0.003,1.683-0.009,2.523,0
            c0.553,0.005,0.995,0.458,0.99,1.01c-0.006,0.549-0.452,0.99-1,0.99c-0.003,0-0.007,0-0.01,0c-0.833-0.008-1.663-0.003-2.493,0
            C348.924,14.519,348.429,14.522,347.931,14.522z"/>
        </g>
      </g>
    </a>

    <a v-on:click="$emit('update:selectedWedge', 1)" ref="1" :class="['slice',this.selectedWedgeClass(1)]" :id="1"  role="link" tabindex="0" xlink:target="_parent" >
      <g class="segments">
        <path :class="this.cssClassForServiceLineLevel(1, 2)" id="Path_72_7_" d="M465.337,237.772l56.116-56.116
        c-29.524-29.582-66.347-50.827-106.733-61.582l-20.536,76.641C421.108,203.886,445.655,218.05,465.337,237.772z"/>
        <path :class="this.cssClassForServiceLineLevel(1, 1)" id="Path_83_7_" d="M409.22,293.883l56.112-56.114
        c-19.682-19.721-44.23-33.886-71.154-41.056l-20.533,76.64C387.107,276.939,399.38,284.022,409.22,293.883z"/>
        <path :class="this.cssClassForServiceLineLevel(1, 3)" id="Path_60_7_" d="M521.448,181.654l56.114-56.115
        C538.197,86.097,489.1,57.77,435.251,43.431l-20.536,76.641C455.101,130.827,491.925,152.073,521.448,181.654z"/>
      </g>
      <g class="icon">
        <ellipse id="Ellipse_4" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="5" stroke-miterlimit="10" cx="505.296" cy="76.154" rx="30.071" ry="30.071"/>
        <path fill="#00AEEF" d="M500.933,74.597c-0.494,0-0.924-0.366-0.99-0.869c0,0,0,0,0-0.001l0,0c-0.001-0.002-0.001-0.004-0.001-0.007
      c-0.057-0.433-0.086-0.871-0.086-1.304c0-0.025,0.001-0.05,0.003-0.075c0.007-0.632,0.074-1.263,0.203-1.886
      c0.111-0.542,0.64-0.888,1.183-0.776c0.54,0.112,0.888,0.641,0.776,1.182c-0.178,0.856-0.21,1.731-0.097,2.599
      c0.001,0.002,0.001,0.004,0.001,0.006c0,0,0,0,0,0.001c0.072,0.547-0.312,1.049-0.86,1.122
      C501.021,74.594,500.976,74.597,500.933,74.597z"/>
    <path fill="#00AEEF" d="M513.493,82.38c-0.428,0-0.824-0.277-0.956-0.707c-0.162-0.528,0.135-1.087,0.663-1.25
      c3.542-1.087,5.941-4.308,5.971-8.015c-0.124-4.746-4.076-8.528-8.896-8.413c-4.024,0.105-7.421,2.929-8.259,6.867
      c-0.064,0.299-0.262,0.553-0.536,0.688c-0.275,0.136-0.596,0.138-0.873,0.005c-0.673-0.321-1.425-0.492-2.172-0.495
      c-1.325-0.024-2.587,0.484-3.536,1.41s-1.48,2.165-1.497,3.49c0,0.262,0.024,0.541,0.071,0.818c0.094,0.544-0.272,1.062-0.816,1.155
      c-0.553,0.097-1.062-0.273-1.154-0.817c-0.066-0.389-0.101-0.782-0.101-1.169c0.023-1.872,0.77-3.611,2.101-4.91
      c1.311-1.277,3.033-1.978,4.859-1.978c0.029,0,0.059,0,0.089,0c0.64,0.002,1.286,0.095,1.908,0.271
      c1.392-4.258,5.3-7.218,9.864-7.337c0.094-0.002,0.188-0.004,0.28-0.004c5.759,0,10.518,4.607,10.668,10.397
      c-0.036,4.617-3.003,8.6-7.385,9.945C513.689,82.365,513.59,82.38,513.493,82.38z"/>
    <path fill="#00AEEF" d="M506.286,90.312h-0.99c-0.553,0-1-0.448-1-1s0.447-1,1-1h0.99c0.542,0,0.984-0.441,0.984-0.984
      c0-0.542-0.441-0.983-0.984-0.983h-15.864c-0.553,0-1-0.448-1-1s0.447-1,1-1h15.864c1.646,0,2.984,1.338,2.984,2.984
      C509.27,88.975,507.931,90.312,506.286,90.312z"/>
    <path fill="#00AEEF" d="M501.329,82.379h-10.907c-0.553,0-1-0.448-1-1s0.447-1,1-1h10.907c0.543,0,0.984-0.441,0.984-0.983
      c0-0.542-0.441-0.984-0.984-0.984h-0.99c-0.553,0-1-0.448-1-1s0.447-1,1-1h0.99c1.646,0,2.984,1.338,2.984,2.984
      C504.314,81.042,502.975,82.379,501.329,82.379z"/>
      </g>
    </a>

    <a v-on:click="$emit('update:selectedWedge', 2)" ref="2" :class="['slice',this.selectedWedgeClass(2)]" :id="2"  role="link" tabindex="0" xlink:target="_parent" >
      <g class="segments">
        <path :class="this.cssClassForServiceLineLevel(2, 2)" id="Path_72_5_" d="M506.416,308.92l76.656-20.54
        c-10.778-40.38-32.044-77.191-61.643-106.698l-56.105,56.105C485.055,257.459,499.232,282,506.416,308.92z"/>
        <path :class="this.cssClassForServiceLineLevel(2, 1)" id="Path_83_5_" d="M429.762,329.456l76.651-20.54
        c-7.184-26.92-21.361-51.461-41.093-71.133l-56.102,56.106C419.083,303.725,426.17,315.995,429.762,329.456z"/>
        <path :class="this.cssClassForServiceLineLevel(2, 3)" id="Path_60_5_" d="M583.068,288.377l76.654-20.54
        c-14.37-53.84-42.727-102.922-82.192-142.264l-56.105,56.105C551.024,211.185,572.291,247.996,583.068,288.377z"/>
      </g>
      <g class="icon">
        <ellipse id="Ellipse_2" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="5" stroke-miterlimit="10" cx="621.756" cy="187.04" rx="30.071" ry="30.071"/>
        <g id="Group_1803" transform="translate(16.913 11.538)">
          <path fill="#00AEEF" d="M599.286,195.039c-0.056,0-0.111,0-0.167-0.001c-4.832-0.171-8.593-4.206-8.429-8.993
            c0.117-2.44,1.243-4.679,3.108-6.216v-18.387c0-3.018,2.455-5.473,5.474-5.473s5.474,2.455,5.474,5.473v18.387
            c2.011,1.662,3.17,4.169,3.12,6.788c-0.044,2.292-0.978,4.431-2.63,6.022C603.625,194.19,601.517,195.039,599.286,195.039z
            M595.133,181.321c-1.467,1.18-2.354,2.918-2.444,4.805c-0.126,3.674,2.771,6.78,6.457,6.912
            c0.043,0.001,0.086,0.001,0.128,0.001c1.722,0,3.339-0.651,4.576-1.842c1.268-1.22,1.983-2.86,2.017-4.619
            c0.039-2.037-0.876-3.986-2.458-5.258c-0.387-0.139-0.662-0.508-0.662-0.941v-18.937c0-1.915-1.558-3.473-3.473-3.473
            c-1.916,0-3.475,1.558-3.475,3.473v18.937C595.799,180.814,595.521,181.184,595.133,181.321z"/>
          <g>
            <ellipse id="Ellipse_60_1_" fill="#00AEEF" cx="599.273" cy="186.618" rx="1.756" ry="1.756"/>
            <path fill="#00AEEF" d="M599.273,189.374c-1.52,0-2.756-1.236-2.756-2.756c0-1.52,1.236-2.756,2.756-2.756
              s2.756,1.236,2.756,2.756C602.029,188.137,600.792,189.374,599.273,189.374z M599.273,185.861c-0.417,0-0.756,0.339-0.756,0.756
              s0.339,0.756,0.756,0.756s0.756-0.339,0.756-0.756S599.69,185.861,599.273,185.861z"/>
          </g>
          <path fill="#00AEEF" d="M599.273,185.861c-0.553,0-1-0.448-1-1v-22.825c0-0.552,0.447-1,1-1s1,0.448,1,1v22.825
            C600.273,185.414,599.826,185.861,599.273,185.861z"/>
          <path fill="#00AEEF" d="M618,163.037h-9.363c-0.553,0-1-0.448-1-1s0.447-1,1-1H618c0.553,0,1,0.448,1,1
            S618.553,163.037,618,163.037z"/>
          <path fill="#00AEEF" d="M614.49,170.06h-5.853c-0.553,0-1-0.448-1-1s0.447-1,1-1h5.853c0.553,0,1,0.448,1,1
            S615.042,170.06,614.49,170.06z"/>
          <path fill="#00AEEF" d="M612.149,177.083h-3.512c-0.553,0-1-0.448-1-1s0.447-1,1-1h3.512c0.553,0,1,0.448,1,1
            S612.701,177.083,612.149,177.083z"/>
        </g>
      </g>
    </a>

    <a v-on:click="$emit('update:selectedWedge', 3)" ref="3" :class="['slice',this.selectedWedgeClass(3)]" :id="3"  role="link" tabindex="0" xlink:target="_parent" >
      <g class="segments">
        <path :class="this.cssClassForServiceLineLevel(3, 2)" id="Path_72_2_" d="M506.417,391.076l76.656,20.54
        c10.856-40.359,10.844-82.872-0.035-123.225l-76.641,20.536C513.648,335.829,513.655,364.171,506.417,391.076z"/>
        <path :class="this.cssClassForServiceLineLevel(3, 1)" id="Path_83_2_" d="M429.765,370.533l76.652,20.537
        c7.238-26.906,7.231-55.247-0.021-82.149l-76.639,20.538C433.382,342.91,433.385,357.081,429.765,370.533z"/>
        <path :class="this.cssClassForServiceLineLevel(3, 3)" id="Path_60_2_" d="M583.072,411.611l76.654,20.539
        c14.475-53.812,14.458-110.496-0.048-164.3l-76.641,20.536C593.916,328.739,593.928,371.252,583.072,411.611z"/>
      </g>
      <g class="icon">
        <ellipse id="Ellipse_5" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="5" stroke-miterlimit="10" cx="667.419" cy="350.7" rx="30.076" ry="30.076"/>
        <g id="Group_8" transform="translate(16.918 16.918)">
          <path fill="#00AEEF" d="M661.904,335.658c-0.553,0-1-0.447-1-1s0.447-1,1-1c0.416,0,0.754-0.338,0.754-0.753v-8.773
            c0-1.383-1.125-2.508-2.508-2.508h-19.3c-1.383,0-2.508,1.125-2.508,2.508v8.773c0,0.416,0.338,0.753,0.754,0.753
            c0.553,0,1,0.447,1,1s-0.447,1-1,1c-1.519,0-2.754-1.235-2.754-2.753v-8.773c0-2.486,2.022-4.508,4.508-4.508h19.3
            c2.485,0,4.508,2.022,4.508,4.508v8.773C664.658,334.423,663.423,335.658,661.904,335.658z"/>
          <path fill="#00AEEF" d="M663.658,332.15h-26.315c-0.553,0-1-0.448-1-1s0.447-1,1-1h26.315c0.553,0,1,0.448,1,1
            S664.211,332.15,663.658,332.15z"/>
          <path fill="#00AEEF" d="M659.274,326.449c-0.384,0-0.744-0.149-1.017-0.42c-0.271-0.272-0.422-0.633-0.422-1.018
            c0-0.792,0.643-1.438,1.434-1.44l0,0c0.002,0,0.004,0,0.006,0c0.383,0,0.742,0.148,1.014,0.418
            c0.272,0.271,0.423,0.631,0.425,1.016c0,0.796-0.645,1.442-1.437,1.444C659.276,326.449,659.275,326.449,659.274,326.449z
            M659.276,325.571c-0.001,0-0.001,0-0.002,0C659.275,325.571,659.275,325.571,659.276,325.571L659.276,325.571L659.276,325.571
            c0.309-0.001,0.56-0.253,0.56-0.562c0-0.147-0.06-0.292-0.164-0.396c-0.105-0.104-0.25-0.164-0.397-0.164h-0.001v0.122
            l-0.001-0.122c-0.308,0.001-0.559,0.252-0.559,0.56c0.001,0.151,0.061,0.295,0.165,0.398
            C658.983,325.511,659.127,325.571,659.276,325.571C659.275,325.571,659.275,325.571,659.276,325.571z"/>
          <path fill="#00AEEF" d="M639.097,344.431c-0.552,0-1-0.447-1-0.999c0-0.553,0.447-1.001,0.999-1.001
            c1.384-0.001,2.509-1.126,2.509-2.509v-3.509c0-0.553,0.447-1,1-1s1,0.447,1,1v3.509
            C643.605,342.407,641.583,344.43,639.097,344.431C639.097,344.431,639.097,344.431,639.097,344.431z"/>
          <path fill="#00AEEF" d="M646.114,347.939c-0.553,0-1-0.447-1-1s0.447-1,1-1c0.416,0,0.754-0.338,0.754-0.754v-8.772
            c0-0.553,0.447-1,1-1s1,0.447,1,1v8.772C648.868,346.704,647.633,347.939,646.114,347.939z"/>
          <path fill="#00AEEF" d="M661.904,344.431c-2.486,0-4.509-2.022-4.509-4.509v-3.509c0-0.553,0.447-1,1-1s1,0.447,1,1v3.509
            c0,1.384,1.125,2.509,2.509,2.509c0.553,0,1,0.447,1,1S662.457,344.431,661.904,344.431z"/>
          <path fill="#00AEEF" d="M654.887,347.939c-1.52,0-2.755-1.235-2.755-2.754v-8.772c0-0.553,0.447-1,1-1s1,0.447,1,1v8.772
            c0,0.416,0.339,0.754,0.755,0.754c0.553,0,1,0.447,1,1S655.44,347.939,654.887,347.939z"/>
        </g>
      </g>
    </a>

    <a v-on:click="$emit('update:selectedWedge', 4)" ref="4" :class="['slice',this.selectedWedgeClass(4)]" :id="4"  role="link" tabindex="0" xlink:target="_parent" >
      <g class="segments">
        <path :class="this.cssClassForServiceLineLevel(4, 2)" id="Path_72_9_" d="M465.34,462.226l56.116,56.116
        c29.582-29.524,50.828-66.347,61.582-106.733l-76.641-20.536C499.226,417.996,485.061,442.544,465.34,462.226z"/>
        <path :class="this.cssClassForServiceLineLevel(4, 1)" id="Path_83_9_" d="M409.229,406.109l56.114,56.112
          c19.721-19.682,33.886-44.23,41.056-71.154l-76.64-20.533C426.173,383.995,419.09,396.269,409.229,406.109z"/>
        <path :class="this.cssClassForServiceLineLevel(4, 3)" id="Path_60_9_" d="M521.457,518.337l56.115,56.114
          c39.442-39.365,67.769-88.463,82.108-142.312l-76.641-20.536C572.285,451.99,551.039,488.813,521.457,518.337z"/>
      </g>
      <g class="icon">
        <ellipse id="Ellipse_7" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="5" stroke-miterlimit="10" cx="626.669" cy="501.994" rx="30.071" ry="30.071"/>
        <g id="Group_12" transform="translate(12.437 12.448)">
          <path fill="#00AEEF" d="M601.973,498.668c-0.039,0-0.079-0.002-0.118-0.007c-0.501-0.06-0.879-0.481-0.883-0.985
            c-0.009-1.133-1.565-1.155-1.689-1.155c-0.002,0-0.004,0-0.006,0c-0.067,0-1.647,0.024-1.641,1.142
            c0.003,0.539-0.421,0.983-0.959,1.005c-0.56,0.047-0.997-0.387-1.038-0.924c-0.023-0.312-0.04-0.624-0.04-0.938
            c-0.055-7.086,5.671-12.902,12.764-12.958c0.324,0.002,0.633,0.016,0.941,0.04c3.427,0.277,6.539,1.872,8.766,4.49
            c2.226,2.618,3.299,5.946,3.021,9.372c-0.043,0.521-0.479,0.919-0.996,0.919c-0.016,0-0.031,0-0.046-0.001
            c-0.538-0.024-0.96-0.471-0.955-1.009c0.002-0.237-0.049-0.585-0.513-0.859c-0.627-0.369-1.618-0.374-2.259-0.012
            c-0.492,0.278-0.552,0.641-0.554,0.889c-0.004,0.504-0.382,0.926-0.882,0.985c-0.505,0.059-0.966-0.262-1.088-0.751
            c-0.239-0.954-1.293-1.382-2.231-1.382c0,0,0,0-0.001,0c-0.938,0.001-1.99,0.428-2.228,1.382
            c-0.111,0.445-0.512,0.758-0.971,0.758h-0.001c-0.46-0.001-0.859-0.314-0.97-0.761c-0.235-0.951-1.286-1.378-2.223-1.379
            c-0.001,0-0.002,0-0.003,0c-0.939,0-1.992,0.427-2.229,1.381C602.831,498.36,602.427,498.668,601.973,498.668z M605.173,494.529
            c0.001,0,0.003,0,0.004,0c1.284,0.001,2.418,0.454,3.193,1.214c0.777-0.761,1.912-1.213,3.197-1.214h0.001
            c1.203,0,2.275,0.396,3.045,1.071c0.207-0.212,0.449-0.397,0.725-0.553c1.062-0.601,2.467-0.688,3.628-0.268
            c-0.334-1.868-1.157-3.622-2.42-5.106c-1.88-2.212-4.508-3.559-7.401-3.792c-0.258-0.021-0.52-0.032-0.78-0.034
            c-5.287,0.042-9.683,3.893-10.592,8.93c0.447-0.162,0.95-0.252,1.491-0.256c0.011,0,0.021,0,0.033,0
            c1.059,0,2.125,0.363,2.834,1.075C602.9,494.924,603.97,494.529,605.173,494.529z"/>
          <path fill="#00AEEF" d="M608.37,485.871c-0.553,0-1-0.447-1-1v-2.375c0-0.553,0.447-1,1-1s1,0.447,1,1v2.375
            C609.37,485.424,608.923,485.871,608.37,485.871z"/>
          <path fill="#00AEEF" d="M622.546,489.715c-0.467,0-0.884-0.328-0.979-0.803c-0.109-0.541,0.241-1.068,0.783-1.178
            c2.027-0.408,3.651-1.973,4.138-3.983c0.335-1.388,0.109-2.823-0.635-4.041c-0.745-1.218-1.919-2.073-3.307-2.408
            c-2.865-0.686-5.757,1.077-6.449,3.941c-0.129,0.537-0.675,0.871-1.206,0.737c-0.537-0.129-0.867-0.67-0.737-1.206
            c0.46-1.907,1.635-3.521,3.309-4.544c1.674-1.022,3.647-1.334,5.553-0.872c1.907,0.459,3.521,1.635,4.544,3.309
            s1.333,3.646,0.872,5.553c-0.667,2.765-2.899,4.914-5.687,5.476C622.677,489.709,622.611,489.715,622.546,489.715z"/>
          <path fill="#00AEEF" d="M621.29,477.153c-0.553,0-1-0.447-1-1v-4.23c0-0.553,0.447-1,1-1s1,0.447,1,1v4.23
            C622.29,476.705,621.843,477.153,621.29,477.153z"/>
          <path fill="#00AEEF" d="M616.806,479.01c-0.256,0-0.512-0.098-0.707-0.293l-2.989-2.99c-0.391-0.391-0.391-1.023,0-1.414
            s1.023-0.391,1.414,0l2.989,2.99c0.391,0.391,0.391,1.023,0,1.414C617.318,478.912,617.062,479.01,616.806,479.01z"/>
          <path fill="#00AEEF" d="M631.872,483.496h-4.234c-0.553,0-1-0.447-1-1s0.447-1,1-1h4.234c0.553,0,1,0.447,1,1
            S632.425,483.496,631.872,483.496z"/>
          <path fill="#00AEEF" d="M625.78,479.01c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l2.99-2.99
            c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-2.99,2.99C626.292,478.912,626.036,479.01,625.78,479.01z"/>
          <path fill="#00AEEF" d="M628.77,490.974c-0.256,0-0.512-0.098-0.707-0.293l-2.99-2.99c-0.391-0.391-0.391-1.023,0-1.414
            s1.023-0.391,1.414,0l2.99,2.99c0.391,0.391,0.391,1.023,0,1.414C629.282,490.876,629.026,490.974,628.77,490.974z"/>
          <path fill="#00AEEF" d="M610.719,508.168c-1.847,0-3.35-1.503-3.35-3.35v-7.15c0-0.553,0.447-1,1-1s1,0.447,1,1v7.15
            c0,0.744,0.605,1.35,1.35,1.35c0.745,0,1.351-0.605,1.351-1.35c0-0.553,0.447-1,1-1s1,0.447,1,1
            C614.07,506.665,612.567,508.168,610.719,508.168z"/>
        </g>
      </g>
    </a>

    <a v-on:click="$emit('update:selectedWedge', 5)" ref="5" :class="['slice',this.selectedWedgeClass(5)]" :id="5"  role="link" tabindex="0" xlink:target="_parent" >
      <g class="segments">
        <path :class="this.cssClassForServiceLineLevel(5, 2)" id="Path_72_11_" d="M394.191,503.304l20.54,76.656
        c40.38-10.778,77.191-32.044,106.698-61.643l-56.105-56.105C445.652,481.943,421.111,496.12,394.191,503.304z"/>
        <path :class="this.cssClassForServiceLineLevel(5, 1)" id="Path_83_11_" d="M373.656,426.65l20.54,76.651
          c26.92-7.184,51.461-21.361,71.133-41.093l-56.106-56.102C399.387,415.971,387.116,423.059,373.656,426.65z"/>
        <path :class="this.cssClassForServiceLineLevel(5, 3)" id="Path_60_11_" d="M414.735,579.957l20.54,76.654
          c53.84-14.37,102.922-42.727,142.264-82.192l-56.105-56.105C491.927,547.913,455.115,569.18,414.735,579.957z"/>
      </g>
      <g class="icon">
          <ellipse id="Ellipse_3" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="5" stroke-miterlimit="10" cx="505.643" cy="617.809" rx="30.071" ry="30.071"/>
        <g id="Group_4" transform="translate(16.913 12.43)">
          <path fill="#00AEEF" d="M490.286,607.046c-2.504,0-4.542-2.037-4.543-4.541c-0.001-2.505,2.035-4.544,4.539-4.545
            c0.001,0,0.004,0,0.004,0c2.505,0,4.543,2.038,4.543,4.543S492.791,607.046,490.286,607.046z M490.283,599.96
            c-1.401,0.001-2.541,1.142-2.54,2.543c0.001,1.402,1.142,2.543,2.543,2.543c1.402,0,2.543-1.141,2.543-2.543
            s-1.141-2.543-2.543-2.543H490.283z"/>
          <path fill="#00AEEF" d="M490.285,624.019c-0.553,0-1-0.447-1-1v-16.974c0-0.553,0.447-1,1-1s1,0.447,1,1v16.974
            C491.285,623.572,490.838,624.019,490.285,624.019z"/>
          <path fill="#00AEEF" d="M499.673,624.019h-18.775c-0.553,0-1-0.447-1-1s0.447-1,1-1h18.775c0.553,0,1,0.447,1,1
            S500.226,624.019,499.673,624.019z"/>
          <path fill="#00AEEF" d="M491.528,600.182c-0.154,0-0.311-0.035-0.457-0.111c-0.491-0.253-0.684-0.856-0.431-1.347l4.985-9.666
            c-0.896-0.296-1.846-0.391-2.796-0.272c-3.491,0.438-5.976,3.633-5.538,7.124c0.068,0.548-0.32,1.048-0.868,1.116
            c-0.552,0.062-1.048-0.32-1.116-0.868c-0.574-4.585,2.688-8.782,7.274-9.356c1.684-0.213,3.375,0.09,4.886,0.869
            c0.49,0.253,0.683,0.856,0.43,1.347l-5.479,10.623C492.24,599.985,491.89,600.182,491.528,600.182z"/>
          <path fill="#00AEEF" d="M498.741,614.72c-0.058,0-0.115-0.005-0.173-0.015c-0.262-0.047-0.494-0.194-0.646-0.412l-6.042-8.627
            c-0.316-0.452-0.207-1.075,0.246-1.393c0.451-0.317,1.075-0.207,1.393,0.246l5.422,7.742c0.532-0.515,0.974-1.12,1.299-1.787
            c0.748-1.532,0.854-3.264,0.3-4.876s-1.704-2.912-3.236-3.66c-0.496-0.242-0.702-0.841-0.46-1.337s0.838-0.704,1.337-0.46
            c2.013,0.981,3.522,2.688,4.251,4.807c0.729,2.117,0.588,4.392-0.395,6.403c-0.62,1.271-1.561,2.373-2.721,3.187
            C499.147,614.658,498.946,614.72,498.741,614.72z"/>
          <path fill="#00AEEF" d="M482.905,611.059c-0.968,0-1.939-0.17-2.876-0.512c-2.105-0.767-3.786-2.309-4.732-4.34
            c-0.364-0.783-0.605-1.613-0.716-2.467c-0.034-0.264,0.037-0.529,0.199-0.74c0.163-0.21,0.401-0.347,0.665-0.381l11.182-1.43
            c0.55-0.063,1.05,0.318,1.119,0.865c0.069,0.548-0.317,1.049-0.865,1.119l-10.109,1.292c0.09,0.307,0.203,0.606,0.338,0.897
            c0.721,1.547,2.001,2.721,3.604,3.305c1.604,0.584,3.338,0.508,4.886-0.212c0.5-0.234,1.096-0.016,1.328,0.484
            c0.233,0.5,0.017,1.095-0.484,1.328C485.316,610.795,484.113,611.059,482.905,611.059z"/>
        </g>
      </g>
    </a>

    <a v-on:click="$emit('update:selectedWedge', 6)" ref="6" :class="['slice',this.selectedWedgeClass(6)]" :id="6"  role="link" tabindex="0" xlink:target="_parent" >
      <g class="segments">
        <path :class="this.cssClassForServiceLineLevel(6, 2)" id="Path_72_3_" d="M312.036,503.305l-20.54,76.656
        c40.359,10.856,82.872,10.844,123.225-0.035l-20.536-76.641C367.283,510.537,338.941,510.544,312.036,503.305z"/>
        <path :class="this.cssClassForServiceLineLevel(6, 1)" id="Path_83_3_" d="M332.579,426.653
          l-20.537,76.652c26.906,7.238,55.247,7.231,82.149-0.021l-20.538-76.639C360.202,430.27,346.031,430.273,332.579,426.653z"/>
        <path :class="this.cssClassForServiceLineLevel(6, 3)" id="Path_60_3_" d="M291.501,579.96l-20.539,76.654
          c53.812,14.475,110.496,14.458,164.3-0.048l-20.536-76.641C374.373,590.805,331.86,590.817,291.501,579.96z"/>
      </g>
      <g class="icon">
          <ellipse id="Ellipse_8" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="5" stroke-miterlimit="10" cx="351.427" cy="666.19" rx="30.076" ry="30.076"/>
        <g id="Group_14" transform="translate(16.911 16.918)">
          <path id="Path_33" fill="#00AEEF" d="M328.27,649.019c-0.023,0.493-0.165,0.974-0.415,1.4c-0.345,0.5-0.22,1.185,0.28,1.53
            c0.5,0.345,1.185,0.22,1.53-0.28c0.03-0.044,0.057-0.09,0.081-0.137l0.035-0.065c0.43-0.747,0.665-1.59,0.683-2.452
            c-0.079-1.294-0.406-2.56-0.963-3.73c-0.441-0.914-0.714-1.9-0.807-2.911c0.138-1.063,0.652-2.042,1.449-2.759
            c0.46-0.397,0.51-1.092,0.113-1.552c-0.397-0.46-1.092-0.51-1.552-0.113c-0.04,0.035-0.078,0.073-0.113,0.113
            c-1.209,1.129-1.954,2.667-2.091,4.315c0.079,1.294,0.406,2.56,0.963,3.73C327.905,647.022,328.178,648.008,328.27,649.019z
            M340.337,649.019c-0.02,0.496-0.163,0.978-0.414,1.406c-0.333,0.508-0.191,1.19,0.317,1.523c0.508,0.333,1.19,0.191,1.523-0.317
            c0.033-0.05,0.062-0.103,0.086-0.158c0.43-0.747,0.665-1.591,0.684-2.453c-0.081-1.292-0.408-2.556-0.964-3.724
            c-0.441-0.915-0.714-1.901-0.807-2.912c0.138-1.064,0.652-2.044,1.448-2.763c0.393-0.463,0.337-1.157-0.126-1.551
            c-0.411-0.349-1.013-0.349-1.424,0c-1.209,1.128-1.955,2.666-2.091,4.314c0.079,1.294,0.407,2.56,0.964,3.73
            C339.973,647.026,340.246,648.01,340.337,649.019L340.337,649.019z M332.643,654.979c0.429,0.427,1.122,0.427,1.551,0
            c1.451-1.35,2.348-3.191,2.517-5.165c-0.101-1.568-0.5-3.102-1.175-4.52c-0.562-1.164-0.908-2.42-1.019-3.708
            c0.151-1.396,0.819-2.685,1.872-3.613c0.395-0.461,0.342-1.156-0.12-1.551c-0.412-0.353-1.019-0.353-1.431,0
            c-1.45,1.349-2.347,3.19-2.515,5.164c0.101,1.568,0.499,3.103,1.174,4.522c0.562,1.163,0.908,2.419,1.02,3.706
            c-0.151,1.396-0.819,2.685-1.873,3.614C332.218,653.856,332.217,654.548,332.643,654.979L332.643,654.979z M345.687,650.643
            c-0.463-0.393-1.158-0.336-1.55,0.128c-0.353,0.417-0.347,1.03,0.014,1.439l0.038,0.035c0.766,0.616,1.238,1.525,1.3,2.506
            c0,2.973-5.023,5.485-10.971,5.485s-10.972-2.512-10.972-5.485c0.062-0.981,0.534-1.89,1.3-2.505
            c0.409-0.449,0.377-1.145-0.073-1.554c-0.4-0.364-1.005-0.384-1.427-0.046c-1.212,1.028-1.936,2.519-1.993,4.108
            c0,4.306,5.784,7.68,13.166,7.68s13.165-3.373,13.165-7.68C347.629,653.162,346.903,651.669,345.687,650.643L345.687,650.643z"/>
        </g>
      </g>
    </a>

    <a v-on:click="$emit('update:selectedWedge', 7)" ref="7" :class="['slice',this.selectedWedgeClass(7)]" :id="7"  role="link" tabindex="0" xlink:target="_parent" >
      <g class="segments">
        <path :class="this.cssClassForServiceLineLevel(7, 2)" id="Path_72_6_" d="M240.886,462.228
        l-56.116,56.116c29.524,29.582,66.347,50.828,106.734,61.582l20.536-76.641C285.116,496.114,260.568,481.95,240.886,462.228z"/>
        <path :class="this.cssClassForServiceLineLevel(7, 1)" id="Path_83_6_" d="M297.003,406.117
          l-56.112,56.114c19.682,19.721,44.23,33.886,71.154,41.056l20.533-76.64C319.117,423.061,306.843,415.978,297.003,406.117z"/>
        <path :class="this.cssClassForServiceLineLevel(7, 3)" id="Path_60_6_" d="M184.775,518.346
          l-56.114,56.115c39.365,39.442,88.463,67.769,142.312,82.108l20.536-76.641C251.122,569.174,214.299,547.928,184.775,518.346z"/>
      </g>
      <g class="icon">
        <ellipse id="Ellipse_10" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="5" stroke-miterlimit="10" cx="199.204" cy="624.996" rx="30.071" ry="30.071"/>
        <g id="Group_18" transform="translate(16.913 15.973)">
          <path fill="#00AEEF" d="M191.691,603.448h-2.82c-2.625,0-4.76-2.135-4.76-4.76s2.135-4.76,4.76-4.76h2.82c0.552,0,1,0.447,1,1
            v7.52C192.691,603.001,192.243,603.448,191.691,603.448z M188.871,595.928c-1.522,0-2.76,1.238-2.76,2.76s1.238,2.76,2.76,2.76
            h1.82v-5.52H188.871z"/>
          <path fill="#00AEEF" d="M195.45,597.804h-3.76c-0.552,0-1-0.447-1-1s0.448-1,1-1h3.76c0.552,0,1,0.447,1,1
            S196.002,597.804,195.45,597.804z"/>
          <path fill="#00AEEF" d="M195.45,601.566h-3.76c-0.552,0-1-0.447-1-1s0.448-1,1-1h3.76c0.552,0,1,0.447,1,1
            S196.002,601.566,195.45,601.566z"/>
          <path fill="#00AEEF" d="M188.873,620.363h-13.16c-0.552,0-1-0.447-1-1s0.448-1,1-1h13.16c2.04,0,3.7-1.66,3.7-3.7
            s-1.66-3.7-3.7-3.7h-14.1c-3.661,0-6.639-2.979-6.639-6.639c0-3.661,2.979-6.64,6.639-6.64h10.338c0.552,0,1,0.447,1,1
            s-0.448,1-1,1h-10.338c-2.558,0-4.639,2.081-4.639,4.64c0,2.558,2.081,4.639,4.639,4.639h14.1c3.143,0,5.7,2.557,5.7,5.7
            S192.016,620.363,188.873,620.363z"/>
          <path fill="#00AEEF" d="M179.662,624.121c-0.001,0-0.001,0-0.002,0h0l-3.362-0.004c-1.687-0.001-3.321-0.496-4.725-1.432
            L169,620.971c-0.214-0.144-0.393-0.322-0.535-0.532c-0.593-0.897-0.351-2.101,0.535-2.692l2.571-1.714
            c1.404-0.936,3.039-1.431,4.726-1.432h3.162c2.339-0.033,4.423,1.604,4.891,3.938c0.049,0.294,0.071,0.559,0.071,0.824
            c0,1.271-0.496,2.467-1.396,3.365C182.126,623.627,180.932,624.121,179.662,624.121z M179.661,622.121
            C179.661,622.121,179.661,622.121,179.661,622.121c1.521,0,2.759-1.236,2.76-2.757c0-0.157-0.013-0.312-0.039-0.466
            c-0.266-1.327-1.457-2.298-2.837-2.298c-0.021,0-0.043,0-0.064,0.001h-3.183c-1.292,0.001-2.542,0.38-3.618,1.096l-2.493,1.662
            l2.493,1.662c1.075,0.716,2.326,1.095,3.618,1.096L179.661,622.121z"/>
        </g>
      </g>
    </a>

    <a v-on:click="$emit('update:selectedWedge', 8)" ref="8" :class="['slice',this.selectedWedgeClass(8)]" :id="8"  role="link" tabindex="0" xlink:target="_parent" >
      <g class="segments">
        <path :class="this.cssClassForServiceLineLevel(8, 2)" id="Path_72_4_" d="M199.807,391.08l-76.656,20.54
          c10.778,40.38,32.045,77.191,61.643,106.698l56.105-56.105C221.168,442.541,206.992,418,199.807,391.08z"/>
        <path :class="this.cssClassForServiceLineLevel(8, 1)" id="Path_83_4_" d="M276.461,370.545l-76.651,20.54
          c7.184,26.92,21.361,51.461,41.093,71.133l56.102-56.106C287.141,396.275,280.053,384.005,276.461,370.545z"/>
        <path :class="this.cssClassForServiceLineLevel(8, 3)" id="Path_60_4_" d="M123.155,411.623l-76.654,20.54
          c14.37,53.84,42.727,102.922,82.192,142.264l56.105-56.105C155.199,488.815,133.932,452.004,123.155,411.623z"/>
      </g>
      <g class="icon">
        <ellipse id="Ellipse_11" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="5" cx="72.054" cy="501.96" rx="30.071" ry="30.071"/>
        <g id="Group_20" transform="translate(16.913 16.913)">
          <path fill="#00AEEF" d="M68.298,499.203H41.983c-0.552,0-1-0.447-1-1s0.448-1,1-1h26.315c0.552,0,1,0.447,1,1
            S68.85,499.203,68.298,499.203z"/>
          <path fill="#00AEEF" d="M54.264,475.522c-0.552,0-1-0.447-1-1v-2.631c0-0.553,0.448-1,1-1s1,0.447,1,1v2.631
            C55.264,475.074,54.816,475.522,54.264,475.522z"/>
          <path fill="#00AEEF" d="M59.527,480.744c-0.552,0-1-0.447-1-1v-2.593c0-0.553,0.448-1,1-1s1,0.447,1,1v2.593
            C60.527,480.297,60.079,480.744,59.527,480.744z"/>
          <path fill="#00AEEF" d="M54.264,499.204H43.74c-0.552,0-1-0.447-1-1v-16.53c-0.002-0.976,0.521-1.89,1.365-2.385l9.655-5.638
            c0.309-0.181,0.691-0.183,1.002-0.004c0.311,0.179,0.502,0.509,0.502,0.867v7.899c0,0.553-0.448,1-1,1H53.51v3.264h0.754
            c0.552,0,1,0.447,1,1v10.526C55.264,498.757,54.816,499.204,54.264,499.204z M44.74,497.204h8.524v-8.526H52.51
            c-0.552,0-1-0.447-1-1v-5.264c0-0.553,0.448-1,1-1h0.754v-5.157l-8.15,4.759c-0.231,0.136-0.375,0.387-0.375,0.656V497.204z"/>
          <path fill="#00AEEF" d="M66.545,499.206h-7.018c-0.552,0-1-0.447-1-1v-7.019c0-0.553,0.448-1,1-1h0.754v-2.386h-0.754
            c-0.552,0-1-0.447-1-1v-7.057c0-0.393,0.23-0.749,0.587-0.911c0.358-0.162,0.777-0.1,1.072,0.159l6.418,5.634
            c0.596,0.52,0.939,1.274,0.94,2.069v11.51C67.545,498.759,67.097,499.206,66.545,499.206z M60.527,497.206h5.018v-10.509
            c0-0.216-0.094-0.422-0.257-0.564l-4.761-4.18v3.849h0.754c0.552,0,1,0.447,1,1v4.386c0,0.553-0.448,1-1,1h-0.754V497.206z"/>
          <path fill="#00AEEF" d="M54.264,492.188h-5.263c-0.552,0-1-0.447-1-1s0.448-1,1-1h5.263c0.552,0,1,0.447,1,1
            S54.816,492.188,54.264,492.188z"/>
          <path fill="#00AEEF" d="M54.264,495.696h-5.263c-0.552,0-1-0.447-1-1s0.448-1,1-1h5.263c0.552,0,1,0.447,1,1
            S54.816,495.696,54.264,495.696z"/>
          <path fill="#00AEEF" d="M63.036,495.696h-3.509c-0.552,0-1-0.447-1-1s0.448-1,1-1h3.509c0.552,0,1,0.447,1,1
            S63.588,495.696,63.036,495.696z"/>
        </g>
      </g>
    </a>

    <a v-on:click="$emit('update:selectedWedge', 9)" ref="9" :class="['slice',this.selectedWedgeClass(9)]" :id="9"  role="link" tabindex="0" xlink:target="_parent" >
      <g class="segments">
        <path :class="this.cssClassForServiceLineLevel(9, 2)" id="Path_72_1_" d="M199.806,308.924l-76.656-20.54
          c-10.856,40.359-10.844,82.872,0.035,123.225l76.641-20.536C192.575,364.171,192.568,335.83,199.806,308.924z"/>
        <path :class="this.cssClassForServiceLineLevel(9, 1)" id="Path_83_1_" d="M276.458,329.467
          l-76.652-20.537c-7.238,26.906-7.231,55.247,0.021,82.149l76.639-20.538C272.841,357.09,272.839,342.92,276.458,329.467z"/>
        <path :class="this.cssClassForServiceLineLevel(9, 3)" id="Path_60_1_" d="M123.151,288.389L46.497,267.85
          c-14.475,53.812-14.458,110.496,0.048,164.3l76.641-20.536C112.307,371.261,112.295,328.748,123.151,288.389z"/>
      </g>
      <g class="icon">
          <ellipse id="Ellipse_6" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="5" stroke-miterlimit="10" cx="34.266" cy="350.7" rx="30.076" ry="30.076"/>
        <g id="Group_10" transform="translate(16.464 16.918)">
          <path fill="#00AEEF" d="M29.603,347.939c-1.552,0-2.815-1.263-2.815-2.814v-6.787c0-0.203-0.072-0.396-0.206-0.545l-2.951-3.318
            c-3.654,1.429-7.874,0.794-10.945-1.7c-0.429-0.348-0.494-0.978-0.146-1.407c0.349-0.428,0.978-0.494,1.407-0.146
            c2.672,2.169,6.412,2.619,9.523,1.145c0.403-0.19,0.88-0.092,1.176,0.239l3.43,3.858c0.46,0.517,0.714,1.184,0.711,1.877v6.784
            c0,0.449,0.366,0.814,0.815,0.814s0.815-0.365,0.815-0.814v-6.787c0-1.09-0.399-2.139-1.124-2.954l-4.344-4.887
            c-0.298-0.351-0.793-0.422-1.163-0.186c-2.647,1.841-6.285,1.701-8.814-0.359c-0.428-0.349-0.492-0.979-0.144-1.407
            c0.35-0.427,0.98-0.491,1.407-0.144c1.84,1.5,4.49,1.602,6.441,0.247c1.239-0.795,2.851-0.556,3.781,0.535l4.331,4.872
            c1.05,1.182,1.628,2.702,1.628,4.282v6.787C32.418,346.677,31.155,347.939,29.603,347.939z"/>
          <path fill="#00AEEF" d="M7.82,329.791H4.19c-0.552,0-1-0.448-1-1v-7.26c0-0.552,0.448-1,1-1h3.63c0.552,0,1,0.448,1,1v7.26
            C8.82,329.344,8.372,329.791,7.82,329.791z M5.19,327.791h1.63v-5.26H5.19V327.791z"/>
          <path fill="#00AEEF" d="M7.82,328.883c-0.215,0-0.427-0.069-0.602-0.202c-0.25-0.189-0.398-0.484-0.398-0.798v-5.444
            c0-0.314,0.147-0.609,0.398-0.798c0.25-0.189,0.575-0.25,0.876-0.163l6.352,1.814c0.43,0.123,0.726,0.515,0.726,0.961v1.815
            c0,0.446-0.296,0.839-0.725,0.961l-6.352,1.815C8.004,328.87,7.912,328.883,7.82,328.883z M8.82,323.764v2.793l4.352-1.244
            v-0.306L8.82,323.764z M14.172,326.067h0.01H14.172z"/>
          <path fill="#00AEEF" d="M5.098,347.939c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l4.245-4.244v-14.625
            c0-0.552,0.448-1,1-1s1,0.448,1,1v15.039c0,0.266-0.105,0.52-0.293,0.707l-4.538,4.537
            C5.609,347.842,5.354,347.939,5.098,347.939z"/>
          <path fill="#00AEEF" d="M9.635,347.939c-0.552,0-1-0.447-1-1v-4.54c0-0.404,0.244-0.77,0.617-0.924
            c0.374-0.157,0.804-0.07,1.09,0.217l4.537,4.537c0.391,0.391,0.391,1.023,0,1.414s-1.023,0.391-1.414,0l-2.83-2.83v2.126
            C10.635,347.492,10.187,347.939,9.635,347.939z"/>
          <path fill="#00AEEF" d="M20.071,327.976c-2.303,0-4.176-1.873-4.176-4.176s1.874-4.176,4.176-4.176s4.176,1.874,4.176,4.176
            S22.374,327.976,20.071,327.976z M20.071,321.624c-1.2,0-2.176,0.976-2.176,2.176c0,1.2,0.976,2.176,2.176,2.176
            c1.2,0,2.176-0.976,2.176-2.176C22.247,322.6,21.271,321.624,20.071,321.624z"/>
        </g>
      </g>
    </a>

    <a v-on:click="$emit('update:selectedWedge', 10)" ref="10" :class="['slice',this.selectedWedgeClass(10)]" :id="10"  role="link" tabindex="0" xlink:target="_parent" >
      <g class="segments">
        <path :class="this.cssClassForServiceLineLevel(10, 2)" id="Path_72_8_" d="M240.883,237.775
          l-56.116-56.116c-29.582,29.524-50.827,66.347-61.582,106.734l76.641,20.536C206.997,282.004,221.162,257.456,240.883,237.775z"/>
        <path :class="this.cssClassForServiceLineLevel(10, 1)" id="Path_83_8_" d="M296.994,293.891L240.88,237.78
          c-19.721,19.682-33.886,44.23-41.056,71.154l76.64,20.533C280.05,316.005,287.133,303.732,296.994,293.891z"/>
        <path :class="this.cssClassForServiceLineLevel(10, 3)" id="Path_60_8_" d="M184.766,181.663
          l-56.115-56.114c-39.442,39.365-67.769,88.463-82.108,142.312l76.641,20.536C133.938,248.01,155.184,211.187,184.766,181.663z"/>
      </g>
      <g class="icon">

          <ellipse id="Ellipse_9" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="5" stroke-miterlimit="10" cx="85.591" cy="186.817" rx="30.071" ry="30.071"/>
        <g id="Group_16" transform="translate(15.991 16.913)">
          <path fill="#00AEEF" d="M59.962,177.201c-0.224,0-0.449-0.075-0.635-0.228c-5.619-4.626-6.427-12.962-1.801-18.581
            c0.763-0.926,1.648-1.745,2.633-2.432c0.452-0.316,1.077-0.206,1.393,0.247c0.316,0.453,0.206,1.077-0.247,1.393
            c-0.835,0.583-1.587,1.278-2.234,2.064c-3.925,4.768-3.24,11.84,1.528,15.766c0.426,0.351,0.487,0.981,0.136,1.408
            C60.537,177.077,60.251,177.201,59.962,177.201z"/>
          <path fill="#00AEEF" d="M64.036,174.138c-0.233,0-0.468-0.082-0.658-0.248c-3.92-3.431-4.319-9.411-0.889-13.332
            c0.188-0.214,0.385-0.42,0.589-0.615c0.398-0.381,1.032-0.368,1.414,0.033c0.381,0.399,0.367,1.032-0.033,1.414
            c-0.162,0.155-0.318,0.318-0.466,0.486c-2.703,3.09-2.389,7.805,0.702,10.51c0.416,0.364,0.458,0.995,0.094,1.411
            C64.591,174.022,64.314,174.138,64.036,174.138z"/>
          <path fill="#00AEEF" d="M78.074,184.094c-0.334,0-0.673-0.025-1.014-0.076c-1.751-0.263-3.294-1.193-4.346-2.617
            c-1.052-1.424-1.486-3.173-1.223-4.924c0.153-1.394-0.498-2.773-1.705-3.562c-2.072-1.473-3.432-3.63-3.863-6.096
            c-0.896-5.132,2.551-10.036,7.683-10.932c5.13-0.902,10.036,2.55,10.932,7.683c0.095,0.544-0.27,1.062-0.813,1.157
            c-0.547,0.096-1.062-0.27-1.157-0.813c-0.707-4.046-4.57-6.76-8.618-6.057c-4.045,0.707-6.762,4.572-6.056,8.618
            c0.339,1.944,1.412,3.644,3.019,4.788c1.809,1.18,2.803,3.286,2.562,5.473c-0.189,1.262,0.114,2.483,0.848,3.478
            c0.735,0.995,1.812,1.644,3.036,1.828c2.496,0.373,4.808-1.281,5.247-3.774c0.051-0.281,0.077-0.561,0.076-0.837
            c-0.001-0.552,0.445-1.001,0.997-1.003c0.001,0,0.002,0,0.003,0c0.551,0,0.999,0.446,1,0.997
            c0.001,0.398-0.035,0.801-0.107,1.196C84.006,181.844,81.249,184.094,78.074,184.094z"/>
          <path fill="#00AEEF" d="M72.839,167.254c-0.49,0-0.91-0.336-0.977-0.835c-0.146-1.263,0.186-2.424,0.924-3.353
            c0.738-0.929,1.793-1.516,2.972-1.651c2.434-0.275,4.641,1.473,4.92,3.907c0.063,0.549-0.331,1.045-0.879,1.108
            c-0.55,0.063-1.044-0.331-1.108-0.879c-0.074-0.648-0.396-1.229-0.907-1.634c-0.511-0.406-1.153-0.59-1.797-0.514
            c-0.648,0.074-1.229,0.396-1.634,0.907s-0.588,1.149-0.514,1.796c0.062,0.545-0.322,1.072-0.866,1.14
            C72.928,167.252,72.883,167.254,72.839,167.254z"/>
        </g>
      </g>
    </a>

    <a v-on:click="$emit('update:selectedWedge', 11)" ref="11" :class="['slice',this.selectedWedgeClass(11)]" :id="11"  role="link" tabindex="0" xlink:target="_parent" >
      <g class="segments">
        <path :class="this.cssClassForServiceLineLevel(11, 2)" id="Path_72_10_" d="M312.032,196.696
          l-20.54-76.656c-40.38,10.778-77.191,32.044-106.698,61.643l56.105,56.105C260.571,218.057,285.112,203.88,312.032,196.696z"/>
        <path :class="this.cssClassForServiceLineLevel(11, 1)" id="Path_83_10_" d="M332.567,273.35l-20.54-76.651
          c-26.92,7.184-51.461,21.361-71.133,41.093L297,293.894C306.836,284.029,319.107,276.941,332.567,273.35z"/>
        <path :class="this.cssClassForServiceLineLevel(11, 3)" id="Path_60_10_" d="M291.488,120.043
          l-20.54-76.654c-53.84,14.37-102.922,42.727-142.264,82.192l56.105,56.105C214.297,152.088,251.108,130.821,291.488,120.043z"/>
      </g>
      <g class="icon">

          <ellipse id="Ellipse_12" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="5" stroke-miterlimit="10" cx="193.062" cy="76.154" rx="30.071" ry="30.071"/>
        <g id="Group_22" transform="translate(16.913 16.912)">
          <path fill="#00AEEF" d="M172.982,73.015c-0.32,0-0.634-0.153-0.827-0.437c-1.968-2.887-3.143-7.872-3.143-13.334
            c0-5.463,1.175-10.448,3.143-13.334c0.311-0.456,0.933-0.574,1.39-0.263c0.456,0.311,0.574,0.933,0.263,1.39
            c-1.724,2.528-2.795,7.206-2.795,12.208c0,5.001,1.071,9.679,2.795,12.208c0.311,0.457,0.193,1.079-0.263,1.39
            C173.372,72.959,173.176,73.015,172.982,73.015z"/>
          <path fill="#00AEEF" d="M179.32,73.015c-0.194,0-0.39-0.056-0.562-0.174c-0.456-0.311-0.574-0.933-0.263-1.39
            c1.724-2.528,2.795-7.206,2.795-12.208c0-5.002-1.071-9.68-2.795-12.208c-0.311-0.456-0.193-1.078,0.263-1.39
            c0.457-0.312,1.078-0.193,1.39,0.263c1.968,2.885,3.143,7.87,3.143,13.334c0,5.464-1.175,10.449-3.143,13.334
            C179.953,72.862,179.639,73.015,179.32,73.015z"/>
          <path fill="#00AEEF" d="M189.308,59.368h-26.283c-0.552,0-1-0.448-1-1s0.448-1,1-1h26.283c0.552,0,1,0.448,1,1
            S189.86,59.368,189.308,59.368z"/>
          <path fill="#00AEEF" d="M186.677,52.35h-21.051c-0.552,0-1-0.448-1-1s0.448-1,1-1h21.051c0.552,0,1,0.448,1,1
            S187.229,52.35,186.677,52.35z"/>
          <path fill="#00AEEF" d="M187.788,66.382h-23.275c-0.552,0-1-0.448-1-1s0.448-1,1-1h23.275c0.552,0,1,0.448,1,1
            S188.34,66.382,187.788,66.382z"/>
          <path fill="#00AEEF" d="M176.167,73.406c-1.405,0-2.818-0.211-4.199-0.638c-3.613-1.117-6.575-3.574-8.34-6.919
            c-1.765-3.345-2.122-7.176-1.005-10.789c1.117-3.613,3.574-6.575,6.918-8.34c6.903-3.642,15.486-0.991,19.129,5.914
            c1.07,2.028,1.636,4.313,1.637,6.608l0,0c0.004,2.83-0.864,5.637-2.457,7.973l2.353,4.74c0.17,0.344,0.129,0.756-0.107,1.059
            s-0.622,0.445-1.001,0.363l-6.668-1.446C180.452,72.91,178.319,73.406,176.167,73.406z M176.13,47.085
            c-1.912,0-3.85,0.452-5.654,1.404c-2.872,1.516-4.982,4.059-5.941,7.162c-0.959,3.103-0.653,6.393,0.863,9.265
            c3.13,5.929,10.5,8.207,16.427,5.079c0.208-0.11,0.447-0.144,0.679-0.093l4.985,1.081l-1.695-3.416
            c-0.167-0.338-0.13-0.742,0.096-1.044c1.564-2.085,2.423-4.669,2.419-7.276c0,0,0-0.001,0-0.001c0-1.971-0.486-3.934-1.405-5.676
            C184.726,49.443,180.498,47.085,176.13,47.085z M190.308,59.244h0.01H190.308z"/>
        </g>
      </g>
    </a>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'WheelTest',
  props: {
    selectedWedge: {
      type: Number,
      required: true
    },
    allStudies: {
      type: Array,
      required: true
    }
  },
  methods: {
    selectedWedgeClass(wedge) {
      return this.selectedWedge === wedge ? 'selected' : 'unselected';
    },
    cssClassForServiceLineLevel(servicelineIndex, levelIndex) {
      const exists = this.allStudies.some(study => {
        return study.service_lines.some(sl => sl.index === servicelineIndex) 
          && study.level.index === levelIndex; 
      });
      return exists ? `level level-${levelIndex}` : 'level';
    }
  }
};
</script>

<style scoped>
svg {
	width: auto;
  display: block;
  margin: 0 auto;
}

.slice .segments path {
	fill: var(--grey-100);
	stroke: #FFFFFF;
	stroke-width: 6;
	stroke-miterlimit: 10;
	transition: all 400ms ease-in-out;
	position: relative;
}

.slice:focus {
  outline: none;
}

.slice:focus .segments path {
  stroke: var(--primary-cerulean);
  stroke-width: 0.063rem;
  filter: 0 0 0.063rem 0.188rem rgba(77, 187, 255, .3);
}

.slice.selected .segments path,
.slice.unselected:hover .segments path {
	fill: var(--grey-300);
}

.slice:hover {
	cursor: pointer;
}

.slice:hover .segments path {
  fill: var(--grey-200);
}

.slice.unselected .segments .level-1,
.slice.selected .segments .level-1,
.slice.unselected:hover .segments .level-1,
.slice.selected:hover .segments .level-1 {
	fill: var(--level-1);
}

.slice.unselected .segments .level-2,
.slice.selected .segments .level-2,
.slice.unselected:hover .segments .level-2,
.slice.selected:hover .segments .level-2  {
	fill: var(--level-2);
}

.slice.unselected .segments .level-3,
.slice.selected .segments .level-3,
.slice.unselected:hover .segments .level-3,
.slice.selected:hover .segments .level-3  {
	fill: var(--level-3);
}

.slice:hover > .icon > ellipse,
.slice:focus > .icon > ellipse {
	transition: all 400ms ease-in-out;
}

.slice.on > .icon > ellipse {
  fill: var(--primary-cerulean);
	stroke: #fff;
	transition: all 400ms ease-in-out;
}

.slice.on > .icon > g > path,
.slice.on > .icon > g > g > path,
.slice.on > .icon > path,
.slice.on > .icon > g > path > ellipse {
  fill: #fff;
	transition: all 400ms ease-in-out;
}

.icon ellipse {
	fill: #fff;
	stroke: #fff;
	stroke-width: 0.313rem;
}

.slice .level {
	stroke: #fff;
	stroke-width: 0.313rem;
}

.slice.selected .icon ellipse {
  fill: var(--primary-cerulean);
}

.slice.selected .icon g > *,
.slice.selected .icon > * {
  fill: #fff;
}

</style>