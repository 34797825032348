<template>
  <div class="panel">
    <div class="main" :style="styleObject">
      <h3>Service Lines</h3>
      <wheel v-bind:selected-wedge.sync="selectedWedge" :all-studies="this.allStudies" />
    </div>
    <service-line-sidebar class="side-panel" :serviceLine="selectedServiceLine" :studies="this.studiesForSelectedServiceLine"/>
  </div>
</template>

<script>
import Wheel from './Wheel';
import ServiceLineSidebar from './ServiceLineSidebar';

export default {
  name: 'NavigatorOverview',
  components: {
    Wheel,
    ServiceLineSidebar
  },
  computed: {
    // Note: this data is going to be for building out the wheel when we do that programatically
    selectedServiceLine() {
      return this.allServicelines.find(serviceLine => serviceLine.index === this.selectedWedge);
    },
    allServicelines() {
      return this.$store.getters['serviceline/all'];
    },
    studiesForSelectedServiceLine() {
      return this.allStudies.filter(study => study.service_lines.map(service_line => service_line.index).includes(this.selectedWedge));
    },
    allStudies() {
      if (!this.$store.getters['project/studies']) {
        return [];
      }
      return this.$store.getters['project/studies'];
    },
    styleObject() {
      if (this.allServicelines.length <= 0) {
        return { '--level-1': 'var(--grey-100)', '--level-2': 'var(--grey-100)', '--level-3': 'var(--grey-100)' };
      }
      const items = this.allServicelines.flatMap(serviceline => serviceline.levels);
      const colours = Array.from(new Set(items.map(level => level.colour))).map(colour => {
        return { keyName: `--level-${items.find(level => level.colour === colour).index}`, colour: colour };
      });
      let styles = {};
      colours.forEach(colourObject => styles[colourObject.keyName] = colourObject.colour);
      return styles;
    }
  },
  data() {
    return {
      selectedWedge: 12
    };
  }
};
</script>

<style scoped>
.panel {
  margin: 0;
  background-color: #fff;
  min-height: 20em;
  display: flex;
}

.main {
  padding: 1rem;
  width: calc(100% - 20rem);
}

.side-panel {
  background-color: var(--grey-200);
  margin: 0;
  padding: 1rem;
  width: 20rem;
}
</style>