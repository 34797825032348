<template>
  <div :class="`level-card ${level.name.toLowerCase()}`" :style="styleObject">
    <h5>{{ level.name }}</h5>
    <span v-html="this.render(level.description)"></span>
    <!-- <a href="#">Order {{ level.name }}</a> -->
    <output-container v-if="this.studies.length > 0" :studies="this.studies" />
  </div>
</template>

<script>
import OutputContainer from './OutputContainer';
import md from '@/utilities/markdown-rendering.js';

export default {
  name: 'LevelCard',
  components: {
    OutputContainer
  },
  props: {
    level: {
      type: Object,
      required: false
    },
    studies: {
      type: Array, 
      required: true
    }
  },
  methods: {
    render(markdown) {
      return md.render(markdown);
    },
  },
  computed: {
    styleObject() {
      return { '--accent-color': this.level.colour };
    }
  }
};
</script>

<style scoped>
.level-card {
  border: 0.063rem solid var(--grey-400);
  background-color: var(--white);
  padding: 0.5em 1em 1em;
  position: relative;
}

h5 {
  position: relative;
  color: var(--grey-700);
  font-weight: 700;
  font-size: .95rem;
}

p {
  margin-top: 0.5em;
  font-size: .9rem;
}

a {
  display: block;
  margin-top: 0.5em;
  font-weight: 700;
  font-size: .9rem;
}

h5:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 2em;
  height: 2em;
  border-radius: 1em;
  background-color: var(--grey-200);
  margin-right: 0.5em;
  margin-left: -0.5em;
  margin-bottom: 0.125em;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 0.3rem 0.35rem;
  background-size: 1.25em 1.25em
}

h5:before {
  background-color: var(--accent-color);
}

.analyze h5:before {
  background-image: url('~@/assets/svg/level-analyze.svg');
}

.certify h5:before {
  background-image: url('~@/assets/svg/level-certify.svg');
}

.explore h5:before {
  background-image: url('~@/assets/svg/level-explore.svg');
}

.level-card:before {
  content: '';
  position: absolute;
  display: inline-block;
  width: calc(100% + 0.125rem);
  height: 0.375rem;
  background-color: var(--accent-color);
  top: -0.375rem;
  left: -0.063rem;
}
</style>