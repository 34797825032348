<template>
  <div :class="this.componentClass" :style="styleObject">
    <h3>{{ this.name }}</h3>
    <p v-if='this.studies.length'>{{ this.description }}</p>
    <div v-else v-html="this.render(this.blurb)"></div>
    <level-card v-for="level in this.levels" :key="level.index" :level="level" :studies="studiesForLevel(level.index)" />
  </div>
</template>

<script>
import LevelCard from './LevelCard';
import md from '@/utilities/markdown-rendering.js';

export default {
  name: 'ServiceLineSidebar',
  components: {
    LevelCard
  },
  props: {
    serviceLine: {
      type: Object,
      required: false
    },
    studies: {
      type: Array,
      required: false
    }
  },
  methods: {
    render(markdown) {
      return markdown ? md.render(markdown) : null;
    },
    studiesForLevel(level) {
      return this.studies.filter(study => study.level.index === level);
    }
  },
  computed: {
    componentClass() {
      if (!this.name) {
        return 'service-line-side-bar-container';
      }
      return `service-line-side-bar-container service-line-${this.name.replace(/\s+/g, '-').toLowerCase()}`;
    },
    name() {
      return !this.serviceLine ? null : this.serviceLine.name;
    },
    description() {
      return !this.serviceLine ? null : this.serviceLine.description;
    },
    blurb() {
      return !this.serviceLine ? null : this.serviceLine.blurb;
    },
    levels() {
      return !this.serviceLine ? [] : this.serviceLine.levels
        .slice()
        .sort((firstLevel, secondLevel) => firstLevel.index > secondLevel.index ? 1 : -1);
    },
    styleObject() {
      return !this.serviceLine ? null : { '--icon-image': `url('${this.serviceLine.icon}')` };
    }
  }
};
</script>

<style scoped>
h3 {
  position: relative;
  font-size: 1.25rem;
}

h3:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 2em;
  height: 2em;
  border-radius: 100%;
  background-color: var(--primary-cerulean);
  margin-right: 0.5em;
  margin-left: -2em;
  margin-bottom: 0.125em;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1.25em 1.25em;
  border: 0.25rem solid #fff;
  background-image: var(--icon-image);
}

p {
  margin-top: 0.25em;
  font-size: 1rem;
}
</style>